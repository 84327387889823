let tips = [
    {
        "text": "Hey favo collega! Ik hoop dat je het nog even vol houdt tot we elkaar weer elke dag mogen zien!"
    },
    {
        "text": "Ik wil even laten weten, dat niks zo leuk is als samen werken en elke dag te lachen met zo een collega als jij!"
    },
    {
        "text": "Je bent een topper, en dat moet jij weten!"
    }
]

export default tips;