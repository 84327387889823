import { IEmployee } from "../../type/i-employee";
import VoiceRecorder from "../VoiceRecorder/VoiceRecorder";

const VoiceRecorderView = (props: { employee: IEmployee, tabValue: number }) => {

    return (
        <VoiceRecorder employee={props.employee} tabValue={props.tabValue}/>
    )
}

export default VoiceRecorderView; 