import { Button } from "@material-ui/core";
import './SendMessageInput.scss'
import React, { useContext, useEffect } from "react"
import { MessageContext } from "../../context/message.context";
import { MessageService } from "../../service/message.service";
import { MessageType } from "../../type/message.type";
import { useHistory } from "react-router-dom";
import FileReaderService from "../../service/filereader.service";
import { ApiContext } from "../../context/api.context";

const SendMessageInput = () => {
    const { messages, setMessages, sendClicked, setSendClicked, recipients, setRecipients } = useContext(MessageContext);
    const [ sendButtonState, setSendButtonState ] = React.useState(true);
    const { userId } = useContext(ApiContext);
    const path = '/success'
    const history = useHistory();
    const service = new MessageService();


    const sendMessages = () => {
        setSendClicked(!sendClicked);
        setSendButtonState(true);
    }

    useEffect(() => {
        if(recipients.length > 0){
            setSendButtonState(false)
        }
        else {
            setSendButtonState(true);
        }
    }, [recipients])

    useEffect(() => {
        if (messages.length > 0) {
                if (recipients.length === messages.length && messages.length !== 0) {
                    messages.forEach((message) => {
                        if (message.messageType === MessageType.AUDIO) {
                            const reader = new FileReaderService();
                            reader.convertToBase64(message.audio).then((result) => {
                                message.base64 = result as string;
                                message.audio = undefined;
                                service.createMessage(message) 
                                .then((result) => {
                                    if(result){
                                        setMessages([]);
                                        setRecipients([]);
                                    }
                                });
                            });
                        }
                        if(message.messageType === MessageType.TEXT){
                            service.createMessage(message).then((result) => {
                                if(result){
                                    setMessages([]);
                                    setRecipients([]);
                                }
                            });
                        }
                    });
                }
            history.push(path)
        }

    }, [messages])

    return (
        <div className="button-wrapper">
            <Button disabled={sendButtonState} className="send-button" color="default" onClick={sendMessages}>Verstuur</Button>
        </div>
    )
}

export default SendMessageInput;