import { createContext, ReactNode, useEffect, useState } from 'react';
import { EmployeeService } from '../service/employee.service';
import { IApiContext } from '../type/i-apicontext';
import { IEmployee } from '../type/i-employee';

export const ApiContext = createContext({} as IApiContext);

export const ApiProvider = (props: { children: ReactNode }) => {
    const [ employees, setEmployees ] = useState<IEmployee[]>([] as IEmployee[]);
    const [ userId, setUserId ] = useState('');
    const employeeService = new EmployeeService();

    const value = {
        employees, setEmployees,
        userId, setUserId
    }

    useEffect(() => {
        employeeService.getAllEmployees()
        .then((result) => {
            setEmployees(result as IEmployee[]);
        })
    }, [userId])

    return (
        <ApiContext.Provider value={value}>
            {props.children}
        </ApiContext.Provider>
    )
}