let inspiration = [
    {
        "text": "Geef een compliment over de persoonlijkheid van je collega."
    },
    {
        "text": "Laat iets terug komen waaraan je collega meteen herkent dat jij het bericht hebt verstuurd. Gebruik bijvoorbeeld jouw humor in het bericht, of iets dat jullie samen delen."
    },
    {
        "text": "Schrijf over wat je zo bijzonder vindt aan jullie band met elkaar."
    }
]

export default inspiration;