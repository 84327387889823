import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import TextMessageInput from '../TextMessageInput/TextMessageInput';
import VoiceRecorderView from '../MessageAreaViews/VoiceRecorderView';
import './MessageContainer.scss';

const MessageContainer = (props: {recipient}) => {
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
            <div className ="paper-wrapper">
            <Paper>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Gesproken bericht" />
                    <Tab label="Tekst bericht" />
                </Tabs>
            </Paper>
            </div>
            {
                value === 0 ?
                <VoiceRecorderView employee={props.recipient} tabValue={value}/>
                :
                <TextMessageInput employee={props.recipient} tabValue={value}/>
            }
        </div>
    )
}

export default MessageContainer;