import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../context/api.context';
import { Alert } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Recipients.scss'
import { MessageContext } from '../../context/message.context';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography
}
    from '@material-ui/core';

const EmployeeFilter = () => {

    const [searchVal, setSearchVal] = React.useState('');
    const [error, setError] = React.useState('');
    const [filteredEmployee, setFilteredEmployee] = React.useState([]);
    const { employees } = useContext(ApiContext);
    const { recipients, setRecipients, replyId } = useContext(MessageContext);

    const addButton = (employee) => {
        if (recipients.length > 1) {
            let err = 'Er kunnen niet meer dan twee ontvangers worden geselecteerd';
            setError(err);
        }
        else if (recipients.indexOf(employee) !== -1) {
            let err = 'U kunt niet twee keer dezelfde persoon toevoegen';
            setError(err);
        }
        else {
            setFilteredEmployee([]);
            setRecipients(old => [...old, employee]);
        }
    };

    const deleteButton = (employee) => {
        setRecipients(recipients.filter(item => item.firstname !== employee.firstname));
    }

    useEffect(() => {
        if (searchVal.length >= 3) {
            const tempList = employees.filter(word => (word.firstname + word.lastname).toLowerCase().includes(searchVal.toLowerCase()));
            setFilteredEmployee(tempList);
        }
        else {
            setFilteredEmployee([]);
        }
    }, [searchVal])

    useEffect(() => {
        if (replyId && employees.length > 1) {
            const employee = employees.find((empl) => empl.employeeId === replyId);
            setRecipients([employee]);
        }
    }, [employees]);

    return (
        <div>
            {error !== '' ?
                <Alert severity="error">{error}</Alert> :
                <div></div>
            }
            <br/>
            <div>
                <TextField
                    id="filled-basic"
                    label="Zoeken naar personen"
                    variant="outlined"
                    className="searchbar"
                    onChange={event => setSearchVal(event.target.value)}
                    autoComplete="off"
                />
            </div>
            <List dense>
                {
                    filteredEmployee.map((employee) => {
                        const labelId = `checkbox-list-secondary-label-${employee}`;
                        return (
                            <ListItem key={employee.employeeId}  onClick={(event) => addButton(employee)}>
                                <ListItemText id={labelId} primary={`${employee.firstname} ${employee.lastname}`} />
                                <ListItemSecondaryAction >
                                    <Button className="delete-button" onClick={(event) => addButton(employee)}>
                                        Toevoegen
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })
                }
            </List>
            {
                recipients.length < 1  ?
                <div className="accordion-wrapper">
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="accordion-summary">Ontvangers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography >
                                Voeg personen toe om een bericht versturen!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                    :
                    <div className="accordion-wrapper">
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography className="accordion-summary">Ontvangers</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List dense>
                                    <div>
                                        {
                                            recipients.map((recipient) => {
                                                const labelId = `checkbox-list-secondary-label-${recipient}`;
                                                return (
                                                    <ListItem key={recipient.employeeId} button className="list-item">
                                                        <ListItemText id={labelId} primary={`${recipient.firstname} ${recipient.lastname}`} />
                                                        <ListItemSecondaryAction>
                                                            <Button className="delete-button" onClick={event => deleteButton(recipient)}>Verwijder</Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })
                                        }
                                    </div>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </div>
            }
        </div>
    )
}

export default EmployeeFilter;