import { ReactNode } from "react";
import { createContext } from "react";
import { IRecorderContext } from "../type/i-recordercontext";
import React, { useState } from "react";
import { IAudioDetails } from "../type/i-audiodetails";

export const RecorderContext = createContext({} as IRecorderContext);
export const RecorderProvider = (props: { children: ReactNode }) => {
    const [ audioDetails, setAudioDetails ] = useState<IAudioDetails>({} as IAudioDetails);

    const value = {
        audioDetails, setAudioDetails
    }

    return (
        <RecorderContext.Provider value={value}>
            {props.children}
        </RecorderContext.Provider>
    )
}