import React from "react";
import ReactAudioPlayer from "react-audio-player";
import './AudioPlayer.scss';

const AudioPlayer = (props: { audio: any }) => {


    return (
        <div className="audio-wrapper">
            <ReactAudioPlayer
                className="audio-player"
                src={props.audio}
                controls
                autoPlay
            />
        </div>
    )
}

export default AudioPlayer;