import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApiProvider } from './context/api.context';
import { MessageProvider } from './context/message.context';
import { RecorderProvider } from './context/recorder.context';



ReactDOM.render(
  <React.StrictMode>
    <ApiProvider>
      <RecorderProvider>
        <MessageProvider>
          <App />
        </MessageProvider>
      </RecorderProvider>
    </ApiProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

