import React, { useContext } from 'react';
import './MessageArea.scss';
import { MessageContext } from '../../context/message.context';
import { ApiContext } from '../../context/api.context';
import MessageContainer from '../MessageContainer/MessageContainer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core';


const MessageArea = () => {

    const { recipients } = useContext(MessageContext);

    return (
        <div>
            <div>
                {
                    recipients.map((person) => {
                        return (
                            <div className="accordion-wrapper">
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="accordion-summary">Bericht aan {person.firstname} {person.lastname}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MessageContainer recipient={person} />
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

export default MessageArea;