import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './MessageSuggestions.scss';
import inspiration from '../../config/inspiration';
import tips from '../../config/tips';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import SmsIcon from '@material-ui/icons/Sms';
import React from "react";

const MessageSuggestions = () => {

    return (
        <div>
            <div className ="accordion-wrapper">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <EmojiObjectsIcon/>
                        <Typography className="accordion-summary">Inspiratie</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                            {
                                inspiration.map((inspiration) => {
                                    return (
                                        <div>
                                            <div>
                                                • {inspiration.text}
                                            </div>
                                            <br />
                                        </div>
                                    )
                                })
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className ="accordion-wrapper">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <SmsIcon/>
                        <Typography className="accordion-summary">Tips</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {
                                tips.map((tip) => {
                                    return (
                                        <div>
                                            <div>
                                                • {tip.text}
                                            </div>
                                            <br />
                                        </div>
                                    )
                                })
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

        </div>
    )
}

export default MessageSuggestions;