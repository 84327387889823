import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { EmployeeService } from '../service/employee.service';
import { IEmployee } from '../type/i-employee';
import { IMessageContext } from '../type/i-employeefilter';
import { IMessage } from '../type/i-message';
import { ApiContext } from './api.context';

export const MessageContext = createContext({} as IMessageContext);

export const MessageProvider = (props: { children: ReactNode }) => {
    const [ recipients, setRecipients ] = useState<IEmployee[]>([]);
    const [ sender, setSender ] = useState<IEmployee>();
    const [ receivedMessage, setReceivedMessage ] = useState<IMessage>();
    const [ replyId, setReplyId ] = useState();
    const [ messages, setMessages ] = useState<IMessage[]>([] as IMessage[])
    const [ sendClicked, setSendClicked ] = useState(false);
    const { employees, userId } = useContext(ApiContext);
    const service = new EmployeeService();

    const value = {
        recipients, setRecipients,
        messages, setMessages,
        sendClicked, setSendClicked,
        receivedMessage, setReceivedMessage,
        sender, setSender,
        replyId, setReplyId
    }

    useEffect(() => {
        if(userId){
            service.getEmployeeById(userId)
            .then((result) => {
                setSender(result[0] as IEmployee);
            });
        }
    }, [employees])

    return (
        <MessageContext.Provider value={value}>
            {props.children}
        </MessageContext.Provider>
    )
}