import { Button } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { ApiContext } from "../../context/api.context";
import { MessageContext } from "../../context/message.context";
import { EmployeeService } from "../../service/employee.service";
import FileReaderService from "../../service/filereader.service";
import { MessageService } from "../../service/message.service";
import { IEmployee } from "../../type/i-employee";
import { MessageType } from "../../type/message.type";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import './Inbox.scss';

const Inbox = () => {
    const { receivedMessage, setReceivedMessage } = useContext(MessageContext);
    const [ audio, setAudio ] = React.useState<any>();
    const [ user, setUser ] = React.useState<IEmployee>({} as IEmployee);
    const { employees } = useContext(ApiContext);
    const messageService = new MessageService();
    const employeeService = new EmployeeService();
    const fileReaderService = new FileReaderService();
    const { id } = useParams();

    const replyButton = () => {
        if(user){
            window.location.href = `/user/${user._id}/${receivedMessage.sender.employeeId}`;
        }
    }

    useEffect(() => {
        messageService.getMessage(id).then((result) => {
            setReceivedMessage(result[0]);
        })
    }, [id])

    useEffect(() => {
        if (receivedMessage) {
            fileReaderService.convertToMp3(receivedMessage.base64).then((result) => {
                setAudio(result);
            });
            employeeService.getEmployeeByEmail(receivedMessage.recipientEmail)
            .then((result) => {
                setUser(result[0] as IEmployee);
            })
        }

    }, [receivedMessage])

    return (
        <div className="message-wrapper">
            {
                receivedMessage && receivedMessage.messageType === MessageType.TEXT ?
                    <div className="message-container">
                        <div className="message-received">
                            Je hebt een bericht gekregen van een collega!
                        </div>
                        <br />
                        <div>
                            Bericht
                        </div>
                        <div className="message-from">
                            van {receivedMessage.sender.firstname} {receivedMessage.sender.lastname}
                        </div>
                        <br />
                        <div className="message-body-text">
                            {receivedMessage.body}
                        </div>
                    </div>
                    :
                    receivedMessage && receivedMessage.messageType === MessageType.AUDIO ?
                        <div className="message-container">
                            <div className="message-received">
                                Je hebt een bericht gekregen van een collega!
                            </div>
                            <br />
                            <div>Bericht</div>
                            <div className="message-from">
                                van {receivedMessage.sender.firstname} {receivedMessage.sender.lastname}
                            </div>
                            <br />
                            <div className="audio-body">
                                <AudioPlayer audio={"data:audio/ogg;base64," + receivedMessage.base64} />
                                <p>Let op werkt niet in Safari of iOS</p>
                            </div>
                        </div>
                        :
                        <div></div>
            }
            <Button className="reply-button" onClick={replyButton}>
                Beantwoorden
            </Button>
        </div>
    )
}

export default Inbox;