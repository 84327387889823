import Recipients from '../Recipients/Recipients';
import MessageArea from '../MessageArea/MessageArea';
import SendMessageInput from '../SendMessageInput/SendMessageInput';
import { useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ApiContext } from '../../context/api.context';
import { MessageContext } from '../../context/message.context';
import './Home.scss';
import MessageSuggestions from '../MessageSuggestions/MessageSuggestions';
import React from 'react';

const Home = () => {
  const params = useParams();
  const { setUserId } = useContext(ApiContext);
  const { setReplyId, sender } = useContext(MessageContext);
  const [ errorText , setErrorText ] = React.useState('');

  useEffect(() => {
    setUserId(params.id);
    if (params.reply) {
      setReplyId(Number(params.reply));
    }
    setTimeout(() => {
      setErrorText('Pagina niet gevonden...')
    }, 500)
  }, [])

  return (
    <div className="home-wrapper">
      {
        sender ? 
        <div>
          <div className="home">
            <Recipients />
            <MessageArea />
            <MessageSuggestions />
          </div>
          <SendMessageInput />
        </div>
          :
          <div className="error">{errorText}</div>
      }
    </div>
  )
}

export default Home;