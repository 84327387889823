import { TextField } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { MessageContext } from "../../context/message.context"
import { IEmployee } from "../../type/i-employee"
import { IMessage } from "../../type/i-message"
import { MessageType } from "../../type/message.type"
import './TextMessageInput.scss'



const TextMessageInput = (props: { employee: IEmployee, tabValue: number }) => {
  const { setMessages, sendClicked, sender } = useContext(MessageContext);
  const [textMessage, setTextMessage] = React.useState('');

  useEffect(() => {
    //value of message area tab
    if(props.tabValue === 1){
      if(textMessage){
        const message = new Object() as IMessage;
        message.body = textMessage;
        message.recipientEmail = props.employee.email;
        message.recipientFirstName = props.employee.firstname;
        message.recipientLastName = props.employee.lastname;
        message.sender = sender;
        message.messageType = MessageType.TEXT;
    
        setMessages(old => [...old, message]);
      }
    }

  }, [sendClicked])

  return (
    <div className ="text-input-wrapper">
      <TextField
        id={`filled-multiline-static-${props.employee.employeeId}`}
        label={`Bericht aan ${props.employee.firstname} ${props.employee.lastname}`}
        multiline
        rows={4}
        placeholder="Vul hier je boodschap in"
        variant="outlined"
        className="message-input"
        onChange={event => setTextMessage(event.target.value)}
      />
    </div>

  )
}

export default TextMessageInput;