import apiConfig from "../config/api.config"

export class EmployeeService {
    url = `${apiConfig.apiUrl}/employee`;

    public getAllEmployees() {
        return new Promise((resolve, reject) => {
            fetch(this.url + '/all')
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			})
			.catch(reject);
        });
    }

    public getEmployeeById(id) {
        return new Promise((resolve, reject) => {
            fetch(this.url + `?id=${id}`)
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			})
			.catch(reject);
        });
    }

    public getEmployeeByEmail(email) {
        return new Promise((resolve, reject) => {
            fetch(this.url + `?email=${email}`)
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			})
			.catch(reject);
        });
    }
}