export class FileReaderService {
    reader = new FileReader();

    convertToBase64 = (blob: Blob) => {
        return new Promise((resolve, reject) => {
            this.reader.readAsDataURL(blob);
            this.reader.onloadend = () => {
                let data = this.reader.result;
                if (data) {
                    resolve(data);
                }
                else {
                    reject();
                }
            }
        });
    }

    convertToMp3 = (base64: string) => {
        return new Promise((resolve, reject) => {
            try {
                let mp3 = window.atob(base64);
                if (mp3) {
                    resolve(mp3);
                }
                else {
                    reject();
                }
            }
            catch (err) {
                console.error(err);
            }

        });
    }
}

export default FileReaderService;