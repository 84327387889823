import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import './Success.scss';
import React, { useEffect } from "react";

const Success = () => {
    const history = useHistory();

    const back = () => {
        history.goBack();
    }
    
    return (
        <div className="success-wrapper">
            <div className="success-message">
                <div className="message-text">
                    Je bericht(en) zijn verzonden!
                </div>
            </div>
            <Button  className="back-button" color="default" onClick={back}>Stuur meer berichten</Button>
        </div>
    )
}

export default Success;