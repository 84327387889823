import React, { useContext, useEffect } from "react";
import './VoiceRecorder.scss';
import { Button } from "@material-ui/core";
import { IEmployee } from "../../type/i-employee";
import { IMessage } from "../../type/i-message";
import { MessageType } from "../../type/message.type";
import { MessageContext } from "../../context/message.context";
import OpusMediaRecorderView from "../CompatibleRecorder/CompatibleRecorder"

const VoiceRecorder = (props: { employee: IEmployee, tabValue: number }) => {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    const { setMessages, sendClicked, sender } = useContext(MessageContext);
    const [audioData, setAudioData] = React.useState([]);

    useEffect(() => {
        //value of message area tab
        if (props.tabValue === 0) {
            if (audioData && audioData.length > 0) {
                const message = new Object() as IMessage;
                message.recipientEmail = props.employee.email;
                message.recipientFirstName = props.employee.firstname;
                message.recipientLastName = props.employee.lastname;
                message.body = 'Audio bericht:'
                message.sender = sender;
                message.messageType = MessageType.AUDIO;
                message.audio = new Blob(audioData);

                setMessages(old => [...old, message]);
            }
        }

    }, [sendClicked])

    return (
        <div>
            <div className="audio-message-wrapper">
                <div className="record-controls">
                    <OpusMediaRecorderView
                        onDataAvailable={(e) => {
                            setAudioData([e.data]);
                        }}
                        render={({ state, start, stop, pause, resume }) => (
                            <div>
                                <Button id="record-button-disabled" onClick={() => {
                                    if (state === 'recording') {
                                        const elem = document.getElementById('record-button-enabled');
                                        if (elem) {
                                            elem.id = 'record-button-disabled';
                                        }
                                        stop()
                                    } else {

                                        const elem = document.getElementById('record-button-disabled');

                                        if (elem) {
                                            elem.id = 'record-button-enabled';
                                        }
                                        start();
                                    }
                                }}></Button>
                                <p></p>

                                {state === 'recording' ? (<p>Opnemen</p>) : ''}
                                {state === 'inactive' ? (<p>Inactief</p>) : ''}
                                {state === 'paused' ? (<p>Gepauzeerd</p>) : ''}
                                {state === 'notInitialized' ? (<p>Inactief</p>) : ''}

                                <p>Let op: werkt niet in Safari of op iOS</p>

                                <audio
                                    src={audioData && audioData.length ? URL.createObjectURL(new Blob(audioData)) : ''}
                                    controls
                                />
                            </div>
                        )}
                    />

                </div>
            </div>
        </div>

    )
}

export default VoiceRecorder;