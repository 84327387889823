import apiConfig from "../config/api.config";
import { IMessage } from "../type/i-message";
import FileReaderService from "./filereader.service";

export class MessageService {
    url = `${apiConfig.apiUrl}/message`;
    fileReaderService = new FileReaderService()

    public createMessage(message: IMessage) {
        //consider promise
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            fetch(this.url + '/create', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(message)
            }).then(() => resolve(true))
            .catch((err) => {
                console.error(err)
                reject();
            });
        });
    }

    public getMessage(id: string) {
        return new Promise((resolve, reject) => {
            fetch(this.url + `?id=${id}`)
                .then((res) => res.json())
                .then((result) => {
                    resolve(result);
                })
                .catch(reject);
        });
    }
}