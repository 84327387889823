import './App.css';
import Home from './component/Home/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Inbox from './component/Inbox/Inbox';
import Header from './component/Header/Header';
import Success from './component/Success/Success';
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    document.title = "Message with love"
  }, [])

  return (
    <Router>
      <div className="app">
        <Header/>
        <div className="content">
        <Switch>
          <Route path="/user/:id/:reply?" component={Home} />
          <Route path="/message/:id" component={Inbox} />
          <Route path="/success" component={Success} />
        </Switch>
        </div>
      </div>

    </Router>

  );
}

export default App;
